import { Typography } from "cakemail-ui-components-v2"
import { useTranslation } from "react-i18next"
import { IBillingInformation } from "../../interfaces/IBillingInformation"
import './styles.scss'

export default function BillingInformation({ billingInformation }: { billingInformation: IBillingInformation }) {
    const { t: translate } = useTranslation()
    return <>
        <Typography className="billing-info" variant="body1"><strong>{translate('billing-information-title')}</strong></Typography>
        <Typography variant="body1">
            {translate('billing-information-name')}: {billingInformation?.customer_name}
        </Typography>
        <Typography variant="body1">
            {translate('billing-information-email')}: {billingInformation?.customer_email}
        </Typography>
        <Typography variant="body1">
            {translate('billing-information-address')}: {billingInformation?.customer_address}
        </Typography>
    </>
}