import { Typography } from "cakemail-ui-components-v2"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CustomerContext } from "../../contexts"
import ContactSelector from "../ContactSelector"
import CurrentSubscription from "../CurrentSubscription"
import './styles.scss'


export default function Header() {
    const customer = useContext(CustomerContext)
    const { t: translate } = useTranslation()

    return <>
        {customer?.authenticatedMode && (
            <Typography className="plan-header" variant="h1L" >
                {translate('plan_billing_headline')}
            </Typography>
        )
        }
        <div className={customer?.authenticatedMode ? "group" : ""}>
            <ContactSelector />
            <CurrentSubscription />
        </div >
    </>
}