import { useContext, useEffect, useState } from "react"
import { PricingPageContext } from "../../contexts"
import { IProduct } from "../../interfaces/IProduct"
import ComparisonTableContent from "../ComparisonTableContent"
import { getPricesForHighestContactTier } from "../lib/getPricesForHighestContactTier"
import { getPricesForLowestContactTier } from "../lib/getPricesForLowestContactTier"


/**
 * Renders the standard price cards component.
 * 
 * @returns The JSX element representing the standard price cards component.
 */
export default function ComparingFeatureTable(): JSX.Element {
    const pricingPageContext = useContext(PricingPageContext)
    const [lowestPrices, setLowestPrices] = useState<IProduct[]>([])
    const [highestPrices, setHighestPrices] = useState<IProduct[]>([])

    /**
     * Changes the prices of products based on the selected contact tier and currency.
     */
    function getLowestPrice() {
        if (pricingPageContext?.mobileViewPlanType && pricingPageContext?.isMobileView) {
            setLowestPrices(pricingPageContext.products.filter((product) =>
                product.name === pricingPageContext?.mobileViewPlanType
            ).map((product: IProduct) => {
                const price = getPricesForLowestContactTier(product, pricingPageContext.currency)
                return {
                    ...product,
                    prices: price ? [price] : [],
                }
            }))
        }
        else if (pricingPageContext?.products) {
            setLowestPrices(pricingPageContext.products.map((product: IProduct) => {
                const price = getPricesForLowestContactTier(product, pricingPageContext.currency)
                return {
                    ...product,
                    prices: price ? [price] : [],
                }
            }))
        }
    }

    function getHighestPrice() {
        if (pricingPageContext?.mobileViewPlanType && pricingPageContext?.isMobileView) {
            setHighestPrices(pricingPageContext.products.filter((product) =>
                product.name === pricingPageContext?.mobileViewPlanType
            ).map((product: IProduct) => {
                const price = getPricesForHighestContactTier(product, pricingPageContext.currency)
                return {
                    ...product,
                    prices: price ? [price] : [],
                }
            }))
        }
        else if (pricingPageContext?.products) {
            setHighestPrices(pricingPageContext.products.map((product: IProduct) => {
                const price = getPricesForHighestContactTier(product, pricingPageContext.currency)
                return {
                    ...product,
                    prices: price ? [price] : [],
                }
            }))
        }
    }

    useEffect(getLowestPrice, [pricingPageContext])
    useEffect(getHighestPrice, [pricingPageContext])

    return <>
        <ComparisonTableContent lowestPricedProducts={lowestPrices} highestPricedProducts={highestPrices} />
    </>
}
