import { IPrice } from "../../interfaces/IPrice"

export class FullFeatureSet {
    [key: string]: any

    constructor(price: IPrice) {
        if (price.metadata?.usage_limits !== undefined) {
            Object.keys(price.metadata.usage_limits).forEach((key: any) => {
                this[key] = price.metadata.usage_limits[key]
            })
            //Default features that are not collected with the API
            this["insert_reseller_logo"] = false
            this["cakemail_api"] = true
            this["email_api"] = false
            this["dedicated_sending_ip_option"] = false
            this["live_chat_support"] = false
            this["priority_phone_support"] = false
            this["drip_campaigns"] = true
            this["ai_content_generation"] = true
            this["domain_authentication"] = true
            this["drag_and_drop"] = true
            this["free_templates"] = true
            this["email_personalization"] = true
            this["contact_import"] = true
            this["automated_list_hygiene"] = true
            this["use_simple_segmentation"] = true
            this["use_consent_management"] = true
            this["preference_center"] = true
            this["engagement_statistics"] = true
            this["comprehensive_delivery"] = true
            this["heat_map"] = true
            this["custom_form_builder"] = true
            this["knowledge_base"] = true
            this["guides_and_tutorials"] = true
            this['smart_template_selection'] = true
            this["email_support"] = true
        }
    }

    getFeaturesAndLimits(): any {
        const stack: any = {}
        Object.keys(this).forEach((key: any) => {
            stack[key] = this[key]
        })
        return stack
    }
}
