import { useContext } from "react"
import { CustomerContext, PricingPageContext } from "../../contexts"
import CardComponent from "../CardComponent"
/**
 * Renders a legacy price card component.
 * @returns The legacy price card component.
 */
export default function LegacyPriceCard(): JSX.Element {
    const customerContext = useContext(CustomerContext)
    const pricingPageContext = useContext(PricingPageContext)
    const legacyPlan = customerContext?.subscriptions[0]?.items[0]?.price || pricingPageContext?.contractPrice

    return <>
        <CardComponent price={legacyPlan} />
    </>
}