import { IPrice } from "../../interfaces/IPrice"
import { IProduct } from "../../interfaces/IProduct"

/**
 * Retrieves the lowest price tier for a given product and currency.
 * @param product - The product to retrieve the price tier for.
 * @param currency - The currency.
 * @returns lowest Product of that currency.
 */
export function getPricesForLowestContactTier(product: IProduct, currency: string | undefined): IPrice | undefined {
    // for free products, get the first currency match regardless of contact tier
    if (product.prices[0]?.metadata?.category === "free") {
        return product.prices.find((price: IPrice) => price.currency === currency)
    }
    const lowestPriceForCurrency = product.prices
        .filter(price => price.currency === currency)
        .reduce((min: IPrice, obj: IPrice) => {
            return obj.unit_amount < min.unit_amount ? obj : min
        })
    return lowestPriceForCurrency
}