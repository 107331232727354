import { Box } from "@mui/material"
import { Typography } from "cakemail-ui-components-v2"
import { useTranslation } from "react-i18next"
import { IProduct } from "../../interfaces/IProduct"
import { FeatureCategories } from "../../models/FeatureCategories"
import { EFeatureCategory } from "../../types/EFeatureCategory"
import FeatureTableData from "../FeatureTableData"
import PlanLimitsRow from "../PlanLimitsRow"
import './styles.scss'


export default function ComparisonTableContent({ lowestPricedProducts, highestPricedProducts }: { lowestPricedProducts: IProduct[], highestPricedProducts: IProduct[] }) {
    const { t: translate } = useTranslation()
    const getCurrencySymbol = (currency: string) => {
        const currencySymbols: any[] = [
            { currency: "USD", symbol: '$' },
            { currency: "EUR", symbol: '€' },
            { currency: "CAD", symbol: '$' },
            { currency: "GBP", symbol: '£' },
            { currency: "BRL", symbol: 'R$' },
            { currency: "INR", symbol: '₹' }
        ]
        return currencySymbols.find(item => item.currency === currency)?.symbol
    }
    return (
        <>
            <div className="feature-table-container">
                {lowestPricedProducts.slice().reverse().map((product: IProduct) => {
                    return <Box className="starting-price">
                        <Typography className="product-name" variant="h1">{translate(`plan-title-${product.name}`)}</Typography>
                        {product?.prices[0]?.metadata?.category === "free" ? (

                            <Typography className="starting-price-text" variant="h3S">
                                {getCurrencySymbol(product?.prices[0].currency.toUpperCase())}0
                            </Typography>
                        ) : (
                            <Typography className="starting-price-text" variant="h3S">
                                {`${translate("comparison-table-plan-amount")}
                                    ${getCurrencySymbol(product?.prices[0].currency.toUpperCase())}${product?.prices[0].unit_amount / 100}  
                                    ${product?.prices[0].currency.toUpperCase()}
                                `}
                            </Typography>
                        )}

                    </Box>
                })}
            </div>
            <div className="feature-table-container">
                {highestPricedProducts.slice().reverse().map((product: IProduct) => (
                    <PlanLimitsRow price={product?.prices[0]} />
                ))}
            </div>
            <div style={{ height: '1px', backgroundColor: '#DDDDDD', margin: '8px 0' }} />
            {FeatureCategories.map((category: EFeatureCategory, index: number) => (
                <>
                    <div className="feature-table-container">
                        {lowestPricedProducts.slice().reverse().map((product: IProduct) => (
                            <FeatureTableData price={product?.prices[0]} category={category} />
                        ))}
                    </div>
                    {index !== FeatureCategories.length - 1 && (
                        <div
                            style={{
                                height: '1px',
                                backgroundColor: '#DDDDDD',
                                margin: '8px 0',
                            }}
                        />
                    )}
                </>
            ))}
        </>
    )
}
