import { useContext, useEffect, useState } from "react"
import { PricingPageContext } from "../../contexts"
import { IProduct } from "../../interfaces/IProduct"
import CardComponent from "../CardComponent"
import { getPriceByContactTier } from "../lib/getPriceByContactTier"

/**
 * Renders the standard price cards component.
 * 
 * @returns The JSX element representing the standard price cards component.
 */
export default function StandardPriceCards(): JSX.Element {
    const pricingPageContext = useContext(PricingPageContext)
    const [filteredPrices, setFilteredPrices] = useState<IProduct[]>([])

    /**
     * Changes the prices of products based on the selected contact tier and currency.
     */
    function changePrice() {
        if (pricingPageContext?.mobileViewPlanType && pricingPageContext?.isMobileView) {

            setFilteredPrices(pricingPageContext.products.filter((product) =>
                product.name === pricingPageContext?.mobileViewPlanType
            ).map((product: IProduct) => {
                const price = getPriceByContactTier(product, pricingPageContext.selectedContactTier, pricingPageContext.currency)
                return {
                    ...product,
                    prices: price ? [price] : [],
                }

            }))
        }
        else if (pricingPageContext?.products) {
            setFilteredPrices(pricingPageContext.products.map((product: IProduct) => {
                const price = getPriceByContactTier(product, pricingPageContext.selectedContactTier, pricingPageContext.currency)
                return {
                    ...product,
                    prices: price ? [price] : [],
                }
            }))
        }
    }

    useEffect(changePrice, [pricingPageContext])

    return <>
        {filteredPrices?.slice().reverse().map((product: IProduct) => (
            <CardComponent product={product} price={product.prices[0]} key={product.name} />
        ))}
    </>
}
