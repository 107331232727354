import { useContext, useEffect } from "react"
import { CustomerContext } from "../../contexts"

export default function ErrorMessage() {
    const customerContext = useContext(CustomerContext)

    function hideLoading() {
        if (customerContext?.error) {
            customerContext?.setUpdating(false)
        }
    }

    useEffect(hideLoading, [customerContext])

    return customerContext && <>
        {customerContext.error && console.error(customerContext.error)}
    </>
}