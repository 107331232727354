import { Card } from "@mui/material"
import { Button, Chip, Dropdown, GenericWrapperContext, Typography } from "cakemail-ui-components-v2"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Subscription } from "../../../../functions/src/models/Subscription"
import { SubscriptionItem } from "../../../../functions/src/models/SubscriptionItem"
import { CustomerContext, PricingPageContext } from "../../contexts"
import {
    changeSubscription,
    createCheckoutSession,
    createContract,
    previewSubscription,
    refreshTokenIfNecessary
} from "../../functions"
import { IPrice } from "../../interfaces/IPrice"
import { IProduct } from "../../interfaces/IProduct"
import { Messages } from "../../models/Messages"
import { getHttpsCallableError } from "../../utils/data"
import ChangePlan from "../ChangePlan"
import FeaturesDisplay from "../FeatureDisplay"
import LegacyCardContent from "../LegacyCardContent"
import { SimpleDialog } from "../SimpleDialog"
import { getPricesForLowestContactTier } from "../lib/getPricesForLowestContactTier"
import "./styles.scss"


export default function CardComponent({ product, price }: { product?: IProduct, price?: IPrice }) {
    const customerContext = useContext(CustomerContext)
    const pricingPageContext = useContext(PricingPageContext)
    const genericWrapperContext = useContext(GenericWrapperContext)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isActive, setIsActive] = useState<boolean>(false)
    const [isPlanned, setIsPlanned] = useState<boolean>(false)
    const [direction, setDirection] = useState<string>("")
    const [plannedDate, setPlannedDate] = useState<Date>()
    const [previewDetails, setPreviewDetails] = useState<any>(null)
    const [somethingElsePlanned, setSomethingElsePlanned] = useState<boolean>(false)
    const [freePlanContacts, setFreePlanContacts] = useState<number>(2000)
    const { t: translate } = useTranslation()
    const [cardError, setCardError] = useState<object>()

    function isItActive() {
        if (customerContext && pricingPageContext?.contractPrice && price) {
            setIsActive(price.id === pricingPageContext?.contractPrice.id)
            //pricingPageContext?.setMobileViewPlanType('Growth')
        }
        else if (customerContext && price) {
            const subbed =
                customerContext.subscriptions.filter(
                    (subscription: Subscription) =>
                        subscription.items.filter((subItem: SubscriptionItem) => {
                            return subItem.price.id === price.id
                        }).length > 0
                ).length > 0

            setIsActive(
                subbed ||
                (!customerContext.subscriptions[0] && price.metadata.category === "free")
            )
            //pricingPageContext?.setMobileViewPlanType('Growth')
        }
    }

    function textDisplayedOnButton(price: IPrice, product: any) {

        if (product.name === "Free" && pricingPageContext && pricingPageContext?.selectedContactTier > 2000) {
            return translate('plan-contact-limit-exceeded')
        }
        else if (isActive && somethingElsePlanned) {
            return translate('plan-status-active-until') + " " + plannedDate?.toDateString()
        }
        else if (isActive) {
            return translate('plan-status-active')
        }
        else if (!isActive && !isPlanned) {
            return actionLabel(price)
        }
        else if (isPlanned && plannedDate) {
            return translate('plan-status-cancel')
        }

        return translate('unauthenticated-plan-Free')

    }

    function createAContract({ priceId, contractsUrl }: { priceId: string, contractsUrl: string }) {
        if (customerContext && customerContext.token) {
            createContract({ priceId, token: customerContext.token })
                .then(({ data }: any) => {
                    const redirectURL = encodeURIComponent(`https://${customerContext.brand.partnerUrl}/#/account/plans-and-billing`)
                    Messages.requestNavigateTo(`${contractsUrl}?contract=${data}&redirectUrl=${redirectURL}`, customerContext.brand.partnerUrl)
                    // window.location.href = `${contractsUrl}?contract=${data}&redirectUrl=${redirectURL}`
                })
        }
    }

    function isItPlanned() {
        if (customerContext && price && customerContext.subscriptions.length > 0) {
            const schedule = customerContext.subscriptions[0].schedule
            if (schedule && schedule.phases) {
                const planned =
                    schedule.phases[schedule.phases.length - 1]?.items?.filter(
                        (subItem: any) => {
                            return (
                                subItem.price === price.id &&
                                schedule.phases[schedule.phases.length - 1].start_date >
                                new Date().getTime() / 1000
                            )
                        }
                    ).length > 0

                setIsPlanned(planned)
                setPlannedDate(
                    new Date(
                        customerContext.subscriptions[0].schedule?.phases[
                            customerContext.subscriptions[0].schedule?.phases.length - 1
                        ]?.start_date * 1000
                    )
                )
                if (planned) {
                    setPlannedDate(
                        new Date(
                            customerContext.subscriptions[0].schedule?.phases[
                                customerContext.subscriptions[0].schedule?.phases.length - 1
                            ]?.start_date * 1000
                        )
                    )
                }
            }
        }
    }

    function checkSameCategory(price: IPrice, subscription: any) {
        return (
            price?.metadata?.category ===
            subscription?.items[0]?.price?.metadata?.category
        )
    }

    function isThereSomethingElsePlanned() {
        if (customerContext && price && customerContext.subscriptions.length > 0) {
            const schedule = customerContext.subscriptions[0].schedule
            if (schedule && schedule.phases) {
                const planned =
                    schedule.phases[schedule.phases.length - 1]?.items?.filter(
                        (subItem: any) => {
                            return subItem.price !== price.id
                        }
                    ).length > 0

                setSomethingElsePlanned(planned)
            }
        }
    }

    function unauthenticatedButton(product: IProduct) {
        if (product.name === "Free" && pricingPageContext && pricingPageContext?.selectedContactTier > freePlanContacts) {
            return translate('plan-contact-limit-exceeded')
        }
        return translate(`unauthenticated-plan-${product.name}`)
    }
    function changeSelectedCurrency(event: any, newValue: any) {
        if (pricingPageContext && newValue) {
            pricingPageContext.setCurrency(newValue.props.value)
        }
    }

    function validateError(error: any, msgArg: object = {}) {
        const { code } = getHttpsCallableError(error)
        switch (code) {
            case "functions/failed-precondition":
                setCardError({ ...msgArg, error })
                break
        }
    }

    function ChangePlanPreview(price: any, context: any) {
        if (context && pricingPageContext && context.subscriptions[0]) {
            let priceId = price.id
            if (isPlanned) {
                pricingPageContext.setDisableButtons(true)
                priceId = context.subscriptions[0].items[0].price.id
                changeSubscription({
                    token: context.token,
                    priceId,
                    subscriptionId: context.subscriptions[0].id,
                })
                    .then((a: any) => {
                        pricingPageContext.setDisableButtons(false)
                        context.reload()
                    })
                    .catch((error: any) => {
                        validateError(error, { priceId, subscriptionId: context.subscriptions[0].id })
                        refreshTokenIfNecessary(error)
                    })
                    .catch((error: any) => {
                        pricingPageContext.setDisableButtons(false)
                    })
            } else if (
                price.unit_amount >
                (context?.subscriptions[0]?.items[0]?.price?.unit_amount || 0)
            ) {
                pricingPageContext.setDisableButtons(true)
                previewSubscription({
                    priceId: price.id,
                    subscriptionId: context.subscriptions[0].id,
                    token: context.token,
                })
                    .then((r) => {
                        pricingPageContext.setDisableButtons(false)
                        setPreviewDetails(r)
                        setDirection("Upgrade")
                        setIsOpen(true)
                    })
                    .catch((error: any) => {
                        validateError(error, { priceId, subscriptionId: context.subscriptions[0].id })
                        refreshTokenIfNecessary(error)
                    })
                    .catch((error: any) => {

                        pricingPageContext.setDisableButtons(false)
                    })
            } else if (checkSameCategory(price, context.subscriptions[0])) {
                pricingPageContext.setDisableButtons(true)
                previewSubscription({
                    priceId: price.id,
                    subscriptionId: context.subscriptions[0].id,
                    token: context.token,
                })
                    .then((r) => {
                        pricingPageContext.setDisableButtons(false)
                        setPreviewDetails(r)
                        setDirection("Downgrade")
                        setIsOpen(true)
                    })
                    .catch((error: any) => {
                        validateError(error, { priceId, subscriptionId: context.subscriptions[0].id })
                        refreshTokenIfNecessary(error)
                    })
                    .catch((error: any) => {
                        pricingPageContext.setDisableButtons(false)
                    })
            } else if (
                price.unit_amount <
                (context?.subscriptions[0]?.items[0]?.price?.unit_amount || 0)
            ) {
                setDirection("Downgrade")
                setIsOpen(true)
            } else {
                setDirection("Change")
            }
        } else {
            context.setUpdating(true)
            createCheckoutSession({
                token: context.token,
                priceId: price.id,
                returnUrl: "https://" + customerContext?.brand.partnerUrl + "/#/account/plans-and-billing"
                //returnUrl: window.parent?.location?.href || window.location.href,
            })
                .then((a: any) => {
                    context.setUpdating(false)
                    if (a.data?.url && pricingPageContext && customerContext) {
                        //window.parent.location.href = a.data.url
                        Messages.requestNavigateTo(a.data.url, customerContext.brand.partnerUrl)
                    } else {
                        window.location.reload()
                    }
                })
                .catch((error: any) => {
                    refreshTokenIfNecessary(error)
                })
        }
    }
    function actionLabel(targetPrice: IPrice) {
        const tp = targetPrice.unit_amount
        let cp = pricingPageContext?.contractPrice?.unit_amount || customerContext?.subscriptions[0]?.items[0]?.price?.unit_amount || 0
        const subscriptionChange =
            tp >= cp ? (tp === cp ? "Change" : "Upgrade") : "Downgrade"
        return translate('plan-status-' + subscriptionChange)
    }

    useEffect(isItActive, [customerContext, price, pricingPageContext?.contractPrice])
    useEffect(isItPlanned, [customerContext, price])
    useEffect(isThereSomethingElsePlanned, [customerContext, price])

    function findContactTierAssociatedWithFree() {
        pricingPageContext?.products.filter(product => {
            return product.name === "Free"
        }).map(product => {
            return setFreePlanContacts(getPricesForLowestContactTier(product, pricingPageContext?.currency)?.metadata?.usage_limits?.maximum_contacts)
        })
    }

    useEffect(findContactTierAssociatedWithFree, [pricingPageContext?.currency, pricingPageContext?.products])

    return (
        <>
            {cardError &&
                <SimpleDialog
                    open={true}
                    title={translate("plan-error-request-title")}
                    content={translate("plan-error-request-content")}
                    secondaryBtn={{
                        text: translate('plan-status-cancel'),
                        onClick: () => { setCardError(undefined) }
                    }}
                    primaryBtn={{
                        text: translate("plan-error-request-btn"),
                        onClick: () => {
                            let message = `An error has occured while trying to ${direction || "change"} a subscription.\n`
                            message += `customer : ${customerContext?.id} \n`
                            for (const [key, value] of Object.entries(cardError)) {
                                message += `${key}: ${value}\n`
                            }
                            message += "code : functions/failed-precondition \n"
                            Messages.sendZendeskTicketRequest("support", message)
                            setCardError(undefined)
                        }
                    }}
                />
            }
            {price && !product && customerContext && (
                <LegacyCardContent price={price} isActive={isActive} />
            )
            }
            {
                pricingPageContext && price && product && customerContext && (
                    <Card className="shadowed-element" style={{ background: "white" }}>
                        {isPlanned && !isActive && customerContext.authenticatedMode && (
                            <Chip className="card-chip" label={translate("plan-label-upcoming_plan")} variant="outlined" color="secondary" />
                        )}
                        {isActive && customerContext.authenticatedMode && (
                            <Chip className="card-chip" variant="outlined" label={translate("plan-label-current_plan")} color="primary" />
                        )}
                        {!isPlanned && !isActive && customerContext.authenticatedMode && (
                            <div className="chip-padding">{/* Add padding here */}</div>
                        )}
                        <div>
                            <Typography className="product-name" variant="h1">{translate(`plan-title-${product.name}`)}</Typography>
                            <div className='text-container'>
                                <div style={{ padding: "1 rem" }}>{/* Add padding here */}</div>
                                <Typography className="product-description" variant="body1L">
                                    {translate(`plan-description-${product.name}`)}
                                </Typography>
                            </div>
                            <div className="content-block">
                                <div className="your-price-label">
                                    <Typography variant="body2L">
                                        {translate('plan-price-label')}
                                    </Typography>
                                </div>
                                <Typography variant="h2" className={`priceAmount ${pricingPageContext.currency ?? 'usd'}`}>{price.unit_amount / 100}</Typography>
                                {product.name !== "Free" ? (
                                    <div className="currency-dropdown-container"
                                    >

                                        <Dropdown
                                            className="currency-dropdown"
                                            value={pricingPageContext.currency}
                                            onChange={changeSelectedCurrency}
                                            options={pricingPageContext.currencies?.map((item) => ({
                                                text: item.toUpperCase(),
                                                value: item,
                                            }))}
                                            disabled={pricingPageContext.disabledButtons}
                                            size="small"
                                            variant="standard"
                                        />
                                        <Typography variant="body2L"
                                        >/{translate('month')}</Typography>
                                    </div>
                                ) : product.name === "Free" ? (
                                    <div className="credit-card">
                                        <Typography variant="body2L" > {translate('no-credit-card')}</Typography>
                                    </div>
                                ) : (
                                    null
                                )}
                            </div>
                            <div className="content-block">
                                {customerContext?.authenticatedMode ?
                                    <Button
                                        className={`buttonStyle ${product.name}PlanBtn`}
                                        disabled={
                                            isActive || customerContext.updating || pricingPageContext.disabledButtons
                                            || (product.name === "Free" && pricingPageContext.selectedContactTier > 2000)
                                        }
                                        color={product.name !== "Free" ? "primary" : "secondary"}
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            const contractsURL = customerContext?.profile?.billingPortalUrl || genericWrapperContext?.partnerBrand?.config?.contract_redirection_url
                                            if (contractsURL) {
                                                return createAContract({ priceId: price.id, contractsUrl: contractsURL })
                                            }
                                            return ChangePlanPreview(price, customerContext)
                                        }}
                                    >
                                        {textDisplayedOnButton(price, product)}
                                    </Button>
                                    : window.sessionStorage.getItem("hideButton") === "true" ? (
                                        null
                                    ) : (
                                        <Button
                                            className={`buttonStyle ${product.name}PlanBtn`}
                                            color={product.name !== "Free" ? "primary" : "secondary"}
                                            disabled={product.name === "Free" && pricingPageContext && pricingPageContext?.selectedContactTier > freePlanContacts}
                                            onClick={() => {
                                                const contractsURL = customerContext?.profile?.billingPortalUrl || genericWrapperContext?.partnerBrand?.config?.contract_redirection_url
                                                if (contractsURL) {
                                                    return createAContract({ priceId: price.id, contractsUrl: contractsURL })
                                                }
                                                let substitutePartnerUrl = customerContext?.brand?.partnerUrl === "app.staging.courrielleur.com" ? "app.courrielleur.com" : customerContext?.brand?.partnerUrl || ""
                                                window.open(`https://${substitutePartnerUrl}/#/auth/signup`)
                                            }}
                                        >
                                            {unauthenticatedButton(product)}
                                        </Button>
                                    )}
                            </div>
                            <ChangePlan
                                open={isOpen}
                                price={price}
                                setOpen={setIsOpen}
                                previewDetails={previewDetails}
                                setPreviewDetails={setPreviewDetails}
                                direction={direction}
                                validateError={validateError}
                            />
                            <FeaturesDisplay price={price} />
                        </div>
                    </Card >
                )
            }
        </>
    )
}
