import { Button, Icon, Typography } from "cakemail-ui-components-v2"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CustomerContext, PricingPageContext } from "../../contexts"
import { Messages } from "../../models/Messages"
import ComparingFeatureTable from "../ComparingFeatureTable"
import LegacyPriceCard from "../LegacyPriceCard"
import SelectingPlanTypeButtons from "../SelectingPlanTypeButtons"
import StandardPriceCards from "../StandardPriceCards"
import { isSubscribedToLegacyPlan } from "../lib/isSubscribedToLegacyPlan"
import './styles.scss'

export default function PricingGrid() {
    const pricingPageContext = useContext(PricingPageContext)
    const customerContext = useContext(CustomerContext)
    const [showComparisonTable, setShowComparisonTable] = useState<boolean>(false)
    const { t: translate } = useTranslation()

    useEffect(() => {
        /* window.addEventListener("message", (event) => {
             if (event.data.type === "scroll") {
                 const scrollPosition = event.data.height
                 const stickyElement = document.querySelector(".plan-selector")
                 if (stickyElement instanceof HTMLElement) {
                     stickyElement.style.top = `${Math.max(0, scrollPosition - 50)}px`
                 }
             }
         })*/
        window.onresize = Messages.sendHeightToParent
    }, [])

    useEffect(() => {
        Messages.sendHeightToParent()
    }, [pricingPageContext, showComparisonTable])

    useEffect(() => {
        setShowComparisonTable(customerContext?.authenticatedMode || false)
    }, [customerContext?.authenticatedMode])

    useEffect(() => {
        const handleResize = () => {
            pricingPageContext?.setIsMobileView(window.innerWidth <= 990)
        }

        // Add event listener for window resize
        window.addEventListener('resize', handleResize)

        // Call handleResize initially to set the correct state
        handleResize()

    })


    const toggleComparisonTableVisibility = () => {
        setShowComparisonTable(!showComparisonTable)
    }
    const getIconName = () => {
        return showComparisonTable ? "Collapse18" : "Expand18"
    }
    const getText = () => {
        return showComparisonTable ? "comparison-table-hide-btn-label" : "comparison-table-show-btn-label"
    }

    return <>
        {
            isSubscribedToLegacyPlan(customerContext, pricingPageContext) ? (
                <div className="with-legacy-card-container">
                    <LegacyPriceCard />
                    {pricingPageContext?.isMobileView &&
                        (<SelectingPlanTypeButtons />)
                    }
                    <StandardPriceCards />
                </div>
            ) : (
                <>
                    {pricingPageContext?.isMobileView &&
                        (<SelectingPlanTypeButtons />)
                    }
                    <div className="default-card-container">
                        <StandardPriceCards />
                    </div>
                </>
            )
        }
        {!customerContext?.updating && pricingPageContext?.products && !customerContext?.authenticatedMode &&
            <span className="centered-button-billing">
                <Button onClick={toggleComparisonTableVisibility} className="hide-table" color="secondary" variant="outlined">
                    <div className="button-spacing">
                        <Icon className="icon-padding-right" name={getIconName()} />
                        <div className="hide-table">{translate(getText())} </div>
                    </div>
                </Button>
            </span>
        }
        {showComparisonTable && <div className="comparing-all-plan-features">
            <Typography className="comparing-all-plan-features-text" variant="h2" color="darkMatter.main">{translate("comparison-table-title")}</Typography>
        </div>
        }
        {showComparisonTable && pricingPageContext?.isMobileView &&
            <>
                <br />
                <SelectingPlanTypeButtons />
            </>
        }
        {showComparisonTable &&
            <ComparingFeatureTable />}

        {!customerContext?.authenticatedMode && showComparisonTable &&
            <span className="centered-button-billing-bottom">
                <Button onClick={toggleComparisonTableVisibility} className="hide-table" color="secondary" variant="outlined">
                    <div className="button-spacing">
                        <Icon className="icon-padding-right" name={getIconName()} />
                        <div className="hide-table">{translate(getText())} </div>
                    </div>
                </Button>
            </span>
        }
    </>
}