import { CircularProgress } from "@mui/material"
import { useContext } from "react"
import { CustomerContext } from "../../contexts"
import './styles.scss'
/**
 * Renders a loading indicator based on the updating state from the customer context.
 * @returns The JSX element representing the loading indicator.
 */
export default function Loading(): JSX.Element {
    const customerContext = useContext(CustomerContext)
    return <>
        {customerContext?.updating &&
            <div className="loading">
                <div><CircularProgress size="5em" color="secondary" /></div>
                {/*<div><Typography variant="body2L">{translate("billing-plan-loader-label")}</Typography></div>*/}
            </div>
        }
    </>
}