import { eventIdentify } from "cakemail-ui-components-v2"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CustomerContext } from "../../contexts"
import { getAccountStats, getCustomerProfile, getProducts, refreshTokenIfNecessary } from "../../functions"
import { IProduct } from "../../interfaces/IProduct"
import { Messages } from "../../models/Messages"

/**
 * Provides the customer context to its children components.
 * 
 * @param children - The child components to render within the context.
 * @returns The CustomerContextProvider component.
 */
export default function CustomerContextProvider({ children }: { children: JSX.Element[] | JSX.Element }) {
    const [id, setId] = useState<string>()
    const [updating, setUpdating] = useState<boolean>(true)
    const [profile, setProfile] = useState<any>(null)
    const [brand, setBrand] = useState<any>({ partnerUrl: null })
    const [authenticatedMode, setAuthenticatedMode] = useState<boolean>(true)
    const [subscriptions, setSubscriptions] = useState<any[]>([])
    const [products, setProducts] = useState<IProduct[]>([])
    const [token, setToken] = useState<string>("")
    const [accountStats, setAccountStats] = useState<any>(null)
    const [currencyList, setCurrencyList] = useState<string[]>([])
    const [error, setError] = useState<any>(null)
    const [searchParams] = useSearchParams()
    const { i18n } = useTranslation()
    const navigate = useNavigate()

    /**
     * Retrieves the token from the URL query parameters or the session storage.
     * If the token is found, it sets the token, removes it from the query parameters,
     * stores it in the session storage, and navigates to the home page.
     * If the token is not found, it fetches the products and sets the authenticated mode to false.
     */
    function getToken() {
        const _token = searchParams.get("token") || window.sessionStorage.getItem("token")
        const _initCurrency = searchParams.get("initCurrency") || window.sessionStorage.getItem("initCurrency")
        const _hideButton = searchParams.get("hideButton") || window.sessionStorage.getItem("hideButton")
        if (_initCurrency) {
            window.sessionStorage.setItem("initCurrency", _initCurrency)
        }
        if (_hideButton) {
            window.sessionStorage.setItem("hideButton", _hideButton)
        }

        if (!_token) {
            setBrand({ partnerUrl: searchParams.get("brandId") || process.env.REACT_APP_DEFAULT_BRAND_ID })
            return fetchProducts(parseInt(String(searchParams.get("partnerId") || process.env.REACT_APP_DEFAULT_PARTNER_ID)))
            /****/
            /*setProducts([productsForDeveloppement[0]])
            setAuthenticatedMode(false)
            setUpdating(false)
            return*/
            /****/
        }
        setToken(_token)
        searchParams.delete("token")
        window.sessionStorage.setItem("token", _token)
        return navigate("/")
    }

    function insertScript() {
        //if (window.location.href.includes('billing.cakemail.com')) {
        if (profile?.id && !document.querySelector(`script[src="https://script.tapfiliate.com/tapfiliate.js"]`) && window.location.href.includes('billing.cakemail.com')) {
            const script = document.createElement('script')
            script.src = 'https://script.tapfiliate.com/tapfiliate.js' // Replace with the actual path to Tapfiliate script
            script.async = true
            script.onload = function () {
                const customWindow = window as any; // Use 'any' to avoid TypeScript errors
                (function (t, a, p) {
                    customWindow.TapfiliateObject = a
                    customWindow[a] = customWindow[a] || function () {
                        (customWindow[a].q = customWindow[a].q || []).push(arguments)
                    }
                })(customWindow, 'tap')

                customWindow.tap('create', '46148-6ccb5b', { integration: "stripe" })
                customWindow.tap('customer', profile.id)
            }
            document.body.appendChild(script)
        }
        // }
    }

    /**
     * Fetches products from the server and updates the state with the first product.
     * This happens when the user is not authenticated.
     * @returns {void}
     */
    function fetchProducts(partnerId: number): void {
        getProducts({ partnerId })
            .then((p: any) => {
                // use the first product
                setProducts([p.data[0]])
            })
            .then(() => {
                setAuthenticatedMode(false)
                setUpdating(false)
                Messages.informLoadingFinished()
            })
            .catch((error: any) => {
                refreshTokenIfNecessary(error)
            })
            .catch((error: any) => {
                setError(error)
            })
            .finally(() => {
                setUpdating(false)
            })
        return
    }

    /**
     * Refreshes the customer profile by making an API call to get the latest data.
     * If a token is available, it updates the state with the retrieved customer profile data.
     * @returns {void}
     */
    function refreshCustomerProfile(): void {
        if (token && !profile) {
            setUpdating(true)
            getCustomerProfile({ token })
                .then((cp: any) => {
                    if (brand.partnerUrl === "app.cakemail.com") {
                        eventIdentify(cp.data.profile.email, {
                            email: cp.data.profile.email,
                            customer: {
                                planType: cp.data.subscriptions?.[0]?.items?.[0]?.price?.metadata?.category
                            },
                            account: {
                                id: cp.data.profile.metadata?.accountId,
                                partner: cp.data.profile.metadata?.partnerId
                            }
                        })
                    }
                    setId(cp.data.id)
                    setProfile(cp.data.profile)
                    setSubscriptions(cp.data.subscriptions)
                    setProducts(cp.data.products)
                    setBrand(cp.data.brand)
                    setCurrencyList(cp.data.currencies)
                    i18n.changeLanguage((cp.data.language || 'en').replace("_", "-"))
                })
                .catch((error: any) => {
                    refreshTokenIfNecessary(error)
                })
                .catch((error: any) => {
                    setError(error)
                })
                .finally(() => {
                    setUpdating(false)
                })
        }
    }

    /**
     * Handles the account statistics.
     */
    function handleAccountStats() {
        if (!accountStats && token) {
            getAccountStats({ token })
                .then((as: any) => {
                    setAccountStats(as.data)
                })
                .catch((error: any) => {
                    refreshTokenIfNecessary(error)
                })
                .catch((error: any) => {
                    setError(error)
                })
        }
    }

    function reload() {
        refreshCustomerProfile()
        handleAccountStats()
    }
    useEffect(insertScript, [profile])
    useEffect(getToken, [navigate, searchParams])
    useEffect(refreshCustomerProfile, [token, i18n, brand.partnerUrl, profile])
    useEffect(handleAccountStats, [accountStats, token])

    return <>
        <CustomerContext.Provider value={{
            id: id || "",
            profile: profile || null,
            subscriptions: subscriptions || null,
            products: products,
            authenticatedMode: authenticatedMode,
            updating,
            error,
            brand,
            token,
            currencyList,
            setCurrencyList,
            setProfile,
            setSubscriptions,
            setProducts,
            setId,
            setUpdating,
            setError,
            reload
        }}>
            {children}
        </CustomerContext.Provider>
    </>
}
