import { Box, Card, Chip } from "@mui/material"
import { Button, Typography } from "cakemail-ui-components-v2"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CustomerContext, PricingPageContext } from "../../contexts"
import { IPrice } from "../../interfaces/IPrice"
import FeaturesDisplay from "../FeatureDisplay"
import './styles.scss'


/**
 * Renders a legacy price card component.
 * @returns the actual legacy card
 */
export default function LegacyCardContent({ price, isActive }: { price: IPrice, isActive: boolean }) {
    const { t: translate } = useTranslation()
    const customerContext = useContext(CustomerContext)
    const pricingPageContext = useContext(PricingPageContext)
    return <Card className="shadowed-element" style={{ background: "white" }} variant="outlined">
        {isActive ? (
            <Chip className="card-chip" variant="filled" label={translate("plan-label-current_plan")} color="primary" />
        ) : (
            <div style={{ padding: "0.8em" }}>{/* Add padding here */}</div>
        )}
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography className="product-name" variant="h1">{translate('plan-title-Legacy')}</Typography>
            <Typography variant="body2">
                {price?.metadata?.category ? `(${translate('plan-title-' + price.metadata.category)
                    })` : ''}
            </Typography>
        </div >
        <div className="text-container">
            <Typography className="product-description" variant="body1L">
                {translate('plan-description-Legacy')}
            </Typography>
        </div>
        <Box className="content-block">
            <div className="your-price-label">
                <Typography variant="body2L">
                    {translate('plan-price-label')}
                </Typography>
            </div>
            <Typography variant="h2" className="usd priceAmount">{price.unit_amount / 100}</Typography>
            <div className="credit-card">
                <Typography variant="body2L">
                    {pricingPageContext?.contractPrice?.currency ? pricingPageContext?.contractPrice.currency.toUpperCase() : customerContext?.profile.currency.toUpperCase()}
                    &nbsp;/{translate('month')}
                </Typography>
            </div>
        </Box>
        <div className="content-block">
            <Button disabled={true} className="buttonStyle activePlanBtn" color="cloud" size="small">
                {translate('plan-status-active')}
            </Button>
        </div>
        <FeaturesDisplay price={price} />
    </Card >
}