import { Box } from "@mui/material"
import { Button, TColors } from "cakemail-ui-components-v2"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { PricingPageContext } from "../../contexts"
import './styles.scss'

export default function SelectingPlanTypeButtons() {
    const pricingPageContext = useContext(PricingPageContext)
    const { t: translate } = useTranslation()
    function displaySelectedPlan(planType: string) {
        pricingPageContext?.setMobileViewPlanType(planType)
    }
    const getColor = (plan: string) => {
        if (pricingPageContext?.mobileViewPlanType === plan) {
            return "cloud" as TColors
        }
        return 'body1' as TColors
    }
    const getVariant = (plan: string) => {
        if (pricingPageContext?.mobileViewPlanType === plan) {
            return "contained"
        }
        return "text"
    }
    return <>
        <Box className="plan-selector" display="flex" justifyContent="center">
            <Button onClick={() => { displaySelectedPlan("Premium") }} className="buttonStripeIntegration" variant={getVariant("Premium")} color={getColor("Premium")}>{translate("plan-title-premium")}</Button>
            <Button onClick={() => { displaySelectedPlan("Growth") }} className="buttonStripeIntegration" variant={getVariant("Growth")} color={getColor("Growth")}>{translate("plan-title-growth")}</Button>
            <Button onClick={() => { displaySelectedPlan("Free") }} className="buttonStripeIntegration" variant={getVariant("Free")} color={getColor("Free")}>{translate("plan-title-free")}</Button>
        </Box>
    </>
}