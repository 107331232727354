import { EFeatureCategory } from "../types/EFeatureCategory"
import { THumanReadableFeature } from "../types/THumanReadableFeature"

export const FeatureDefinitions: Record<string, THumanReadableFeature> = {
    maximum_contacts: {
        label: "contact_limit",
        category: EFeatureCategory.Fundamentals,
    },
    per_month: {
        label: "monthly_email_sends",
        category: EFeatureCategory.Fundamentals,
    },
    lists: {
        label: "audiences",
        category: EFeatureCategory.Fundamentals,
        value: false
    },
    users: {
        label: "users",
        category: EFeatureCategory.Fundamentals,
    },
    /*(**********)*/
    ai_content_generation: {
        label: "AI-Driven_content_generation",
        category: EFeatureCategory.EmailMarketing,
        value: false
    },
    drag_and_drop: {
        label: "drag_and_drop",
        category: EFeatureCategory.EmailMarketing,
        value: false
    },
    free_templates: {
        label: "free_email_templates",
        category: EFeatureCategory.EmailMarketing,
        value: false
    },
    email_personalization: {
        label: "email_personalization",
        category: EFeatureCategory.EmailMarketing,
        value: false
    },
    domain_authentication: {
        label: "authenticated_sender_domain",
        category: EFeatureCategory.EmailMarketing
    },
    smart_template_selection: {
        label: "smart_template_selection",
        category: EFeatureCategory.EmailMarketing,
        value: false
    },
    /*campaign_blueprints: {
        label: "campaign_blueprints",
        category: EFeatureCategory.EmailMarketing,
        value: false
    },*/
    use_html_editor: {
        label: "HTML_editor",
        category: EFeatureCategory.EmailMarketing,
        value: false,
    },
    professional_templates: {
        label: "profession_templates",
        category: EFeatureCategory.EmailMarketing,
        value: false
    },
    insert_reseller_logo: {
        label: "remove_platform_logo",
        category: EFeatureCategory.EmailMarketing,
        value: false
    },
    dedicated_sending_ip_option: {
        label: "dedicated_sending_ip_option",
        category: EFeatureCategory.EmailMarketing
    },
    use_simple_segmentation: {
        label: "simple_segmentation",
        category: EFeatureCategory.Audience,
        value: false
    },
    contact_import: {
        label: "contact_import",
        category: EFeatureCategory.Audience
    },
    automated_list_hygiene: {
        label: "automated_list_hygiene",
        category: EFeatureCategory.Audience
    },
    custom_form_builder: {
        label: "customizable_signup_form_builder",
        category: EFeatureCategory.Audience
    },
    use_consent_management: {
        label: "consent_management",
        category: EFeatureCategory.Audience,
        value: false
    },
    preference_center: {
        label: "subscriber_preference_center",
        category: EFeatureCategory.Audience
    },
    use_behavioral_segmentation: {
        label: "behavioural_segmentation",
        category: EFeatureCategory.Audience,
        value: false,
    },
    use_contact_export: {
        label: "export_contacts",
        category: EFeatureCategory.Audience,
        value: false
    },
    use_list_redirection: {
        label: "customizable-signup-flow",
        category: EFeatureCategory.Audience,
        value: false
    },
    drip_campaigns: {
        label: "drip-campaigns",
        category: EFeatureCategory.Automations,
        value: false
    },
    use_automation_conditions: {
        label: "customer_journey_mapping",
        category: EFeatureCategory.Automations,
        value: false
    },
    use_ab_split: {
        label: "ab_testing",
        category: EFeatureCategory.Automations,
        value: false
    },
    use_tags_in_automation: {
        label: "tags_automations",
        category: EFeatureCategory.Automations,
        value: false,
    },
    engagement_statistics: {
        label: "engagement_statistics",
        category: EFeatureCategory.Reporting
    },
    heat_map: {
        label: "heatmap_click_reporting",
        category: EFeatureCategory.Reporting
    },
    comprehensive_delivery: {
        label: "comprehensive_delivery_reports",
        category: EFeatureCategory.Reporting
    },
    knowledge_base: {
        label: "knowledge_base",
        category: EFeatureCategory.Support
    },
    guides_and_tutorials: {
        label: "guides_and_tutorials",
        category: EFeatureCategory.Support
    },
    email_support: {
        label: "email_support",
        category: EFeatureCategory.Support
    },
    live_chat_support: {
        label: "live_chat_support",
        category: EFeatureCategory.Support
    },
    priority_phone_support: {
        label: "priority_phone_support",
        category: EFeatureCategory.Support,
        description: "Access fast, direct phone support for your urgent queries and high-priority issues."
    },
    cakemail_api: {
        label: "cakemail_api",
        category: EFeatureCategory.API,
        description: "Access fast, direct phone support for your urgent queries and high-priority issues."
    },
    email_api: {
        label: "email_api",
        category: EFeatureCategory.API,
        description: "Access fast, direct phone support for your urgent queries and high-priority issues."
    },

}
